import { render, staticRenderFns } from "./jilu.vue?vue&type=template&id=513114dc&scoped=true"
import script from "./jilu.vue?vue&type=script&lang=ts"
export * from "./jilu.vue?vue&type=script&lang=ts"
import style0 from "./jilu.vue?vue&type=style&index=0&id=513114dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "513114dc",
  null
  
)

export default component.exports