
import request from '@/plugins/axios'
// export const apiShopLists = (params: any) => request.get('/shop.shop/lists', {params})
/**
 * 获取用户列表 /platformapi/user/lists
 */
export const apiUserLists = (params: any) => request.get('/user/lists', {params})

/**
 * 获取代理列表 /platformapi/agent.agent/lists
 */
export const apiAgentLists = (params: any) => request.get('/agent.agent/lists', {params})
/**
 * 获取代理申请列表 /platformapi/agent.agent_apply/lists
 */
export const apiAgentApplyLists = (params: any) => request.get('/agent.agent_apply/lists', {params})
/**
 * 标记成已联系 /platformapi/agent.agent_apply/mark
 */
export const apiAgentApplyMark = (params: any) => request.post('/agent.agent_apply/mark', params)

/**
 * 获取代理详情 /platformapi/agent.agent/detail
 */
export const apiAgentDetail = (params: any) => request.get('/agent.agent/detail', {params})
/**
 * 获取结算记录 /platformapi/agent.agent/settledLog
 */
export const apiAgentSettledLog = (params: any) => request.get('/agent.agent/settledLog', {params})

/**
 * 添加代理 /platformapi/agent.agent/add
 */
export const apiAgentAdd = (params: any) => request.post('/agent.agent/add', params)
/**
 * 编辑代理 /platformapi/agent.agent/edit
 */
export const apiAgentEdit = (params: any) => request.post('/agent.agent/edit', params)