







































	import Vue from 'vue'
	import Component from 'vue-class-component'
	import { apiAgentSettledLog } from '@/api/user'
	@Component
	export default class SettlementRecords extends Vue {
		// 控制弹窗显示
		dialogVisible = false;
		createTime : any = null
		// 搜索表单数据
		form = {
      order_sn: '',
      sn: '',
			confirm_take_start_time:0,
			confirm_take_end_time:0,
			page_no:1,
			page_size:10,
			id:null
		};
		total = 0
		// 表格数据
		tableData = [];
		changeTime(){
			if(this.createTime){
				this.form.confirm_take_start_time = new Date(this.createTime[0]).getTime() / 1000
				this.form.confirm_take_end_time = new Date(this.createTime[1]).getTime() / 1000
			}else{
				this.form.confirm_take_start_time = 0
				this.form.confirm_take_end_time = 0
			}
			this.getAgentSettledLog()
		}
		// 查询操作
		onSearch() {
			console.log('查询条件:', this.form)
      this.getAgentSettledLog()
		}

		// 重置操作
		onReset() {
			this.form = {
        order_sn: '',
        sn: '',
				confirm_take_start_time:0,
				confirm_take_end_time:0,
				page_no:1,
				page_size:10,
				id:this.form.id
			}
			this.createTime = null
			this.getAgentSettledLog()
		}
		// 分页切换
		handlePageChange(page : number) {
			this.form.page_no = page
			this.getAgentSettledLog()
		}

		// 关闭弹窗
		handleClose() {
			this.form = {
				order_sn: '',
        sn: '',
				confirm_take_start_time:0,
				confirm_take_end_time:0,
				page_no:1,
				page_size:10,
				id:null
			}
			this.createTime = null
		}
		getAgentSettledLog(id?:any){
			if(id){
				this.form.id = id
			}
			apiAgentSettledLog(this.form).then((res)=>{
				this.tableData = res.lists
				this.total = res.count
			})
		}
	}
